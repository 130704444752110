::-webkit-scrollbar {
    width: 9px;
}

::-webkit-scrollbar-track {
    background: #414141;
}

::-webkit-scrollbar-thumb {
    background: #818181;
}

::-webkit-scrollbar-thumb:hover {
    background: #a1a1a1;
}

body {
    overflow-x: hidden;
    background-color: rgb(29, 29, 29);
}
.wholebody {
    animation: mainFadeIn 2s forwards;
}

* {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: Arial, Helvetica, sans-serif;
}
/*Admin style*/

.admin{
    color: #f3f3f3;
    text-align: center;
    min-height: 85vh;
    margin-bottom: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
/*Nav*/

#myNav {
    z-index: 500;
    width: 10%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #181818;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    transition: 0.4s;
}

.icobut {
    display: none;
    cursor: pointer;
    z-index: 1000;
    position: fixed;
    top: 10px;
    right: 10px;
    width: 51px;
    height: 51px;
    padding: 6px 8px;
    background-color: rgba(0, 0, 0, 0.65);
    border-radius: 9px;
}

.x-bar1,
.x-bar2,
.x-bar3 {
    width: 35px;
    height: 5px;
    background-color: #ffffff;
    margin: 6px 0;
    transition: 0.4s;
}

.change .x-bar1 {
    -webkit-transform: rotate(-45deg) translate(-8px, 8px);
    transform: rotate(-45deg) translate(-8px, 8px);
}

.change .x-bar2 {
    opacity: 0;
}

.change .x-bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}

.sect {
    width: 100%;
}

.nav-logo {
    width: 100%;
    height: auto;
    color: #8d8d8d;
    padding: 10% 0px;
    background-color: #000000;
}

.nav-logo img {
    width: 90%;
}

.nav-link {
    width: 100%;
    padding: 15px 0px;
}

.linbutt {
    cursor: pointer;
    width: 100%;
    color: rgb(144, 144, 144);
    font-size: 2.5vh;
    padding: 2vh 0px;
    text-align: center;
    border-bottom: #2d2d2d 1.5px solid;
}

.font-p1 {
    font-size: 2.5vh;
}

.linbutt:first-child {
    border-top: #2d2d2d 1.5px solid;
}

.linbutt a {
    font-size: inherit;
    font-weight: 400;
}

a:link,
a:visited,
a:hover,
a:active {
    color: inherit;
    text-decoration: none;
}

.nav-social {
    width: 100%;
}

.nav-social a {
    padding: 2px;
    margin-bottom: 16px;
}

.social-sym {
    color: #8d8d8d;
    font-size: 22px;
    padding: 8px;
    margin: 0px 2px;
}


/*iframes*/

#screen {
    right: 0px;
    top: 0px;
    float: right;
    width: 90vw;
    height: auto;
}


/*loading*/

#loading {
    margin-left: 0px;
    position: fixed;
    top: 0px;
    bottom: 0px;
    width: 0px;
    overflow: hidden;
    background-color: rgb(29, 29, 29);
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
}

.poicur {
    cursor: pointer;
}

.loadin {
    padding-left: 10%;
    transition: padding 1s cubic-bezier(0.5, 0, 0.5, 1);
    position: fixed;
    animation-name: loadinani;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
}

.loadout {
    position: fixed;
    animation-name: loadoutani;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
}

@keyframes loadinani {
    0% {
        width: 0px;
    }
    100% {
        width: 100%;
    }
}

@keyframes loadoutani {
    0% {
        width: 100%;
    }
    100% {
        margin-left: 100%;
        width: 100%;
    }
}


/*Style Main*/

main {
    width: 100%;
    overflow: hidden;
    padding: 0px 3%;
}

a:link,
a:visited,
a:hover,
a:active {
    color: inherit;
    text-decoration: none;
}

.tebold {
    font-weight: 600 !important;
}

.pinkbold {
    font-weight: 600 !important;
    color: #f33170 !important;
}

.bluebold {
    font-weight: 600 !important;
    color: #23d8f7 !important;
}

.cblue {
    color: #23d8f7 !important;
}

.cpink {
    color: #f33170 !important;
}

.cwhite1 {
    color: #f3f3f3;
}

.cwhite2 {
    color: #e3e3e3;
}

.cwhite3 {
    color: #d3d3d3;
}

.cwhite4 {
    color: #c3c3c3;
}

.cwhite5 {
    color: #b3b3b3;
}

.cwhite6 {
    color: #a3a3a3;
}

.cblack1 {
    color: #414141;
}

.cblack2 {
    color: #515151;
}

.cblack3 {
    color: #616161;
}

.cblack4 {
    color: #717171;
}

.cblack5 {
    color: #818181;
}

.cblack6 {
    color: #919191;
}

.code {
    color: #414141;
    padding: 5px 0px;
    font-size: 2.5vh;
    font-family: cursive;
    font-weight: 400;
}

.matter-main {
    padding-left: 1.5vw;
    position: relative;
}

.matter-head {
    padding-left: 2.5vw;
}

.shadow {
    z-index: -100;
    position: absolute;
    overflow: hidden;
    top: 0px;
    left: 0px;
    padding-top: 5vh;
    font-size: 30vmax;
    font-weight: 600;
    color: #414141;
}

.h1we-text {
    height: 6.4vw;
}

.content-h1 {
    display: inline-block;
    font-size: 7.2vw;
    font-weight: 600;
    padding: 6px 0px;
    cursor: default;
}

.content-h2 {
    display: inline-block;
    font-size: 5.8vw;
    font-weight: 500;
    padding: 6px 0px;
}

.content-h3 {
    display: inline-block;
    font-size: 4.5vw;
    font-weight: 400;
    padding: 6px 0px;
}

.content-h4 {
    display: inline-block;
    font-size: 40px;
    font-weight: 600;
    padding: 6px 0px;
    cursor: default;
}

.content-h5 {
    display: inline-block;
    font-size: 35px;
    font-weight: 500;
    padding: 6px 0px;
}

.content-h6 {
    display: inline-block;
    font-size: 30.5px;
    font-weight: 400;
    padding: 6px 0px;
}

.content-p1 {
    display: inline-block;
    font-size: 26.5px;
    font-weight: 300;
    padding: 6px 0px;
}

.content-p2 {
    display: inline-block;
    font-size: 21px;
    font-weight: 300;
    padding: 6px 0px;
}

.content-p3 {
    display: inline-block;
    font-size: 16px;
    font-weight: 300;
    padding: 6px 0px;
}

.tecenter {
    text-align: center;
}

.tejustify {
    text-align: justify;
}

.teupper {
    text-transform: uppercase;
}

.inline {
    display: inline-block !important;
}

.disnon {
    display: none;
}

.vishid {
    visibility: hidden;
}

.disinbl {
    display: inline-block;
}

.flex-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
}

.flex-row {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
}

.flex-sta {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    flex-grow: 0;
}

.jourwidth {
    width: 65vw;
}

.hwhite:hover {
    color: #f3f3f3;
}

.hpink:hover {
    color: #f33170;
}

.hblue:hover {
    color: #23d8f7;
}


/*work section styles*/

#web-PORT {
    background-color: rgb(29, 29, 29);
}

#web-WTS {
    background-color: rgb(29, 29, 29);
}

#web-HAA {
    background-color: rgb(243, 209, 86);
}

.works {
    width: 100%;
    height: auto;
    display: flex;
    padding: 0 5vw;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
}

.frame {
    text-align: center;
    position: relative;
    width: 300px;
    height: 300px;
    overflow: hidden;
    color: #f3f3f3;
    border: #2d2d2d 2.5px solid;
    margin: 20px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.frame img {
    width: 100%;
}

.frame h1 {
    font-size: 30px !important;
}

.frame .details {
    width: 220px;
    height: 220px;
    padding: 20px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotateY(90deg);
    transform-origin: 50%;
    background: rgba(29, 29, 29, 0.9);
    overflow: hidden;
    transition: all 0.4s linear;
}

.frame:hover .details {
    transform: translate(-50%, -50%) rotateY(0deg);
}


/*blog section style*/

.blog-group {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    flex-wrap: wrap;
    word-break: break-all;
}

.blog-card {
    margin: 15px 10px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    flex-shrink: 0;
    flex-grow: 0;
    max-width: 400px;
    height: auto;
    padding-bottom: 75px;
}

.blog-date {
    color: #f3f3f3;
    font-size: 16px;
    font-weight: 400;
    padding: 6px 0px;
}

.blog-author {
    float: right;
    color: #f3f3f3;
    font-size: 16px;
    font-weight: 400;
    padding: 6px 0px;
}

.fix-bottom {
    position: absolute;
    bottom: 0px;
}


/*blog single style*/

.list {
    padding-left: 4%;
}

.list li {
    padding: 1vmin 0px;
}

.blog-content {
    padding: 2vw 5vw;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
    flex-shrink: 0;
    flex-grow: 0;
}

.blog-box1 {
    width: 60%;
}

.blog-box1 img {
    width: 80%;
}

.blog-box2 {
    width: 35%;
}

.line-pink {
    border-color: #f33170;
}


/*team section style*/

.team-row {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    flex-wrap: wrap;
}

.team-col {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    flex-wrap: wrap;
}

.team-box {
    width: 300px;
    height: 300px;
}

.team-text {
    position: relative;
    padding: 20px;
    text-align: center;
    padding-bottom: 40px;
}

.team-box img {
    width: 100%;
    height: 100%;
}

.team-social {
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.team-social a {
    margin-bottom: 10px;
}


/*contact section style*/

.contwidth {
    width: 45%;
}

.form {
    color: #f3f3f3;
    background-color: #313131;
    padding: 12px;
    border: 0px solid #ccc;
    font-size: 20px;
    margin-top: 6px;
    margin-bottom: 16px;
    width: 100%;
    resize: vertical;
}

#fname {
    width: 48%;
}

#fmail {
    float: right;
    width: 48%;
}

input[type=submit] {
    font-size: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    color: #f3f3f3;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transition: .5s ease;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

 ::placeholder {
    color: #a3a3a3;
    opacity: 1;
    font-size: 16px;
}

 :-ms-input-placeholder {
    color: #a3a3a3;
    font-size: 16px;
}

 ::-ms-input-placeholder {
    color: #a3a3a3;
    font-size: 16px;
}


/*portfolio style*/

.port-contain {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: flex-start;
    flex-shrink: 0;
    flex-grow: 0;
}

.port-box1 {
    margin: 5vmin auto;
    width: 30vw;
}

.port-box2 {
    margin: 5vmin auto;
    padding-top: 6vh;
    width: 60vw;
}

.port-photo {
    margin: 4vmin 0px;
    width: 20vw;
    height: 20vw;
}

.port-photo img {
    height: 100%;
    width: 100%;
}

.face-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.port-photo:hover .face-inner {
    transform: rotateY(180deg);
}

.front-face,
.back-face {
    z-index: 200;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    border-radius: 50%;
}

.back-face {
    z-index: 100;
    transform: rotateY(180deg);
}

.skills {
    margin: 15px 5%;
}

.study {
    padding: 9px 6px;
}

.border-bottom {
    border-bottom: #444 1.5px solid;
}

.port-blog-group .blog-card {
    max-width: 350px;
    align-content: space-between;
}

.port-form {
    width: 650px;
}

footer {
    width: 100%;
}

/* account-info */
.account-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .login-container {
    width: 450px;
    padding: 1.5rem;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    color: #151116;
    border-radius: 10px;
    text-align: center;
  }
  
  .login-form__fieldset {
    border: none;
    outline: none;
  }
  fieldset {
    border: none;
    outline: none;
  }
  
  .login-form__controls {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    row-gap: 10px;
    column-gap: 10px;
    margin-bottom: 10px;
  }
  
  .login-form *:first-child {
    margin-top: 0;
  }
  
  .login-form *:last-child {
    margin-bottom: 0;
  }
  
  .login-form__button {
    padding: 0.5rem 1rem;
    margin: 10px;
  }

  /* error-message */
  .error-message {
    text-align: center;
    font-style: italic;
    color: rgb(158, 0, 0);
  }
  
  .error-message--card {
    max-width: 400px;
    padding: 1rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  }

  /* nav */

  .nav {
    margin: 1rem;
    text-align: center;
  }
  
  .nav__link {
    color: red;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.15s all;
  }
  
  .nav__link:visited {
    color: red;
  }
  
  .nav__link:hover {
    text-decoration: underline;
  }
  
  .nav__link:active {
    color: blue;
  }

  /* Style for the table container */
.table-container {
  max-width: 100%;
  overflow-x: auto;
}

/* Style for the table */
.newtable-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

/* Style for table header */
.newtable-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

/* Style for table rows */
.newtable-table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

/* Style for even rows */
.newtable-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Style for table controls */
.table-controls {
  margin: 20px 0;
}

/* Style for the filter dropdown */
.table-controls select {
  padding: 5px;
  font-size: 16px;
  margin-right: 10px;
}

/* Style for the search input field */
.table-controls input[type="text"] {
  padding: 5px;
  font-size: 16px;
  margin-right: 10px;
}

/* Style for the sort button */
.table-controls button {
  padding: 5px 10px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

/* Style for the edit button */
.Formbutton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}

/* Style for the edit button when disabled */
.Formbutton:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}


/*badge-list*/

.badge-head {
    text-align: center;
    padding: 5px 0px 14px 0px;
}

@keyframes aniBadge {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

.badge-list {
    padding: 0px 30px 25px 30px;
    width: 100%;
    color: #f3f3f3;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}

.badge-list .badge {
    display: inline-block;
    margin: 0px;
    padding: 6px;
    animation: aniBadge 1s ease forwards;
    font-weight: bold;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}

.badge-list .divd {
    display: none;
    transform: scale(1.4) translateY(-5%);
    margin: 0px;
    padding: 0px;
    color: #23d8f7;
    font-weight: bolder;
    transition: all 0.5s ease-in-out;
}


/*Tool tip*/

.tooltip {
    color: #8d8d8d;
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 180px;
    background-color: rgba(45, 45, 45, 0.9);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1000;
    top: 0px;
    right: 150%;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}


/*name animation*/

.name-box {
    padding: 0px 10px;
    width: 280px;
    height: 90px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.name-box .title {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
}

.name-box .title .block {
    width: 0%;
    height: inherit;
    background-color: #f33170;
    position: absolute;
    display: flex;
    animation: mainBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
}

.name-box .title h1 {
    font-size: 22px;
    opacity: 0;
    animation: mainFadeIn 2s forwards;
    animation-delay: 2s;
    display: flex;
    align-items: baseline;
    position: relative;
}

.name-box .title h1 span {
    width: 0px;
    height: 0px;
    border-radius: 50%;
    background-color: #f33170;
    animation: popIn 0.8s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
    animation-delay: 2s;
    margin-left: 5px;
    margin-top: -10px;
    position: absolute;
    bottom: 13px;
    right: -12px;
}

.name-box .role {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    height: 30px;
    margin-top: -10px;
}

.name-box .role .block {
    width: 0%;
    height: inherit;
    background-color: #23d8f7;
    position: absolute;
    display: flex;
    animation: mainBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
    animation-delay: 2s;
}

.name-box .role h2 {
    font-size: 12px;
    opacity: 0;
    animation: secFadeIn 2s forwards;
    animation-delay: 3.2s;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px;
}

@keyframes mainBlock {
    0% {
        width: 0%;
        left: 0;
    }
    50% {
        width: 100%;
        left: 0;
    }
    100% {
        width: 0%;
        left: 100%;
    }
}

@keyframes secBlock {
    0% {
        width: 0%;
        left: 0;
    }
    50% {
        width: 100%;
        left: 0;
    }
    100% {
        width: 0%;
        left: 100%;
    }
}

@keyframes mainFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes secFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.6;
    }
}

@keyframes popIn {
    0% {
        width: 0px;
        height: 0px;
        background: #e9d856;
        opacity: 0;
        border: 0px solid #ddd;
    }
    50% {
        width: 8px;
        height: 8px;
        background: #e9d856;
        opacity: 1;
        bottom: 35px;
    }
    65% {
        width: 6px;
        height: 6px;
        bottom: 0px;
    }
    80% {
        width: 8px;
        height: 8px;
        bottom: 12px;
    }
    100% {
        width: 6px;
        height: 6px;
        background: #e9d856;
        border: 0px solid #222;
        bottom: 6px;
    }
}


/*accordion*/

.accordion {
    background-color: transparent;
    color: #f3f3f3;
    cursor: pointer;
    padding: 15px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    transition: 0.4s;
}

.accordion:hover {
    background-color: #222;
}

.accordion:after {
    content: '\002B';
    color: #f3f3f3;
    font-weight: bold;
    float: right;
    margin-left: 5px;
}

.active:after {
    content: "\2212";
}

.panel {
    padding: 0 15px;
    background-color: transparent;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}


/*slide-in animation on scroll*/

.slideanim {
    visibility: hidden;
}

.slide {
    animation-name: slide;
    -webkit-animation-name: slide;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    visibility: visible;
}

@keyframes slide {
    0% {
        opacity: 0;
        transform: translateY(70%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@-webkit-keyframes slide {
    0% {
        opacity: 0;
        -webkit-transform: translateY(70%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0%);
    }
}


/*popup image*/

#cer-img-pop {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1500;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.8);
}

#cer-dic-cen {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
}

#cer-img {
    display: block;
    max-width: 80vw;
    max-height: 80vh;
}

#cer-cap {
    display: block;
    padding: 15px 25px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #fff;
}

#cer-img,
#cer-cap {
    animation-name: ani-img;
    -webkit-animation-name: ani-img;
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
}

@keyframes ani-img {
    from {
        transform: matrix(0.5, 0, 0, 0.5, 0, 200);
        opacity: 0.2;
    }
    to {
        transform: matrix(1, 0, 0, 1, 0, 0);
        opacity: 1;
    }
}

@-webkit-keyframes ani-img {
    from {
        -webkit-transform: matrix(0.5, 0, 0, 0.5, 0, 200);
        -webkit-opacity: 0.2;
    }
    to {
        -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
        -webkit-opacity: 1;
    }
}

#cer-clo {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
    z-index: 1550;
    cursor: pointer;
}


/*button style*/

.button {
    color: #f3f3f3;
    position: relative;
    width: 200px;
    height: 50px;
    overflow: hidden;
    border: 2px solid #f33170;
}

.layer {
    position: absolute;
    background-color: #f33170;
    overflow: hidden;
    width: 200%;
    height: 100%;
    left: -225%;
    transform: skewX(-45deg);
    transition: .5s ease;
}

.button:hover .layer {
    left: -25%;
}

a {
    cursor: pointer;
}

.button a {
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transition: .5s ease;
}

.botdot {
    background-color: #f33170;
    width: 4px;
    height: 4px;
    position: absolute;
    bottom: 0px;
    right: 0px;
}


/*loading balls style*/

.wrapper {
    display: block;
    width: 200px;
    height: 65px;
    position: absolute;
}

.circle {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    left: 15%;
    transform-origin: 50%;
    animation: circle .5s alternate infinite ease;
}

.circle1 {
    background-color: #f33170;
}

.circle2 {
    background-color: #f3f3f3;
}

.circle3 {
    background-color: #23d8f7;
}

@keyframes circle {
    0% {
        top: 60px;
        height: 5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }
    40% {
        height: 20px;
        border-radius: 50%;
        transform: scaleX(1);
    }
    100% {
        top: 0%;
    }
}

.circle:nth-child(2) {
    left: 45%;
    animation-delay: .2s;
}

.circle:nth-child(3) {
    left: auto;
    right: 15%;
    animation-delay: 0.3s;
}


/*jelly animation*/

.jelly {
    display: inline-block;
}

.jellyb:hover {
    color: #23d8f7;
}

.animjelly {
    animation-name: jelly;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}

@keyframes jelly {
    0% {
        transform: scale(1, 1);
    }
    10% {
        transform: scale(0.75, 1.25);
    }
    20% {
        transform: scale(1.2, 0.8);
    }
    30% {
        transform: scale(0.8, 1.2);
    }
    40% {
        transform: scale(1.15, 0.85);
    }
    50% {
        transform: scale(0.85, 1.15);
    }
    60% {
        transform: scale(1.1, 0.9);
    }
    70% {
        transform: scale(0.9, 1.1);
    }
    80% {
        transform: scale(1.05, 0.95);
    }
    90% {
        transform: scale(0.95, 1.05);
    }
    100% {
        transform: scale(1, 1);
    }
}

@-webkit-keyframes jelly {
    0% {
        transform: scale(1, 1);
    }
    10% {
        transform: scale(0.75, 1.25);
    }
    20% {
        transform: scale(1.2, 0.8);
    }
    30% {
        transform: scale(0.8, 1.2);
    }
    40% {
        transform: scale(1.15, 0.85);
    }
    50% {
        transform: scale(0.85, 1.15);
    }
    60% {
        transform: scale(1.1, 0.9);
    }
    70% {
        transform: scale(0.9, 1.1);
    }
    80% {
        transform: scale(1.05, 0.95);
    }
    90% {
        transform: scale(0.95, 1.05);
    }
    100% {
        transform: scale(1, 1);
    }
}


/*media*/

@media only screen and (min-width: 0px) and (max-width: 319px) {
    .code {
        font-size: 12px;
    }
    .shadow {
        padding-top: 9vh;
        font-size: 40vw;
    }
    .h1we-text {
        height: 12.5vw;
    }
    .content-h1 {
        font-size: 16vw;
    }
    .content-h2 {
        font-size: 12.5vw;
    }
    .content-h3 {
        font-size: 10.5vw;
    }
    .content-h4 {
        font-size: 9vw;
    }
    .content-h5 {
        font-size: 8vw;
    }
    .content-h6 {
        font-size: 7.25vw;
    }
    .content-p1 {
        font-size: 6vw;
    }
    .content-p2 {
        font-size: 5.5vw;
    }
    .content-p3 {
        font-size: 5vw;
    }
    #fname {
        width: 100%;
    }
    #fmail {
        float: left;
        width: 100%;
    }
    .button {
        width: 180px;
        height: 40px;
    }
    /*team media*/
    .team-box {
        width: 90vw;
        height: 90vw;
    }
    .team-box img {
        width: 90vw;
        height: 90vw;
    }
    .team-text {
        min-height: 0px !important;
        height: auto;
    }
}

@media only screen and (min-width: 320px) and (max-width: 375px) {
    .code {
        font-size: 15px;
    }
    .shadow {
        padding-top: 9vh;
        font-size: 40vw;
    }
    .h1we-text {
        height: 12vw;
    }
    .content-h1 {
        font-size: 15vw;
    }
    .content-h2 {
        font-size: 12vw;
    }
    .content-h3 {
        font-size: 10vw;
    }
    .content-h4 {
        font-size: 8vw;
    }
    .content-h5 {
        font-size: 7.25vw;
    }
    .content-h6 {
        font-size: 6.7vw;
    }
    .content-p1 {
        font-size: 6vw;
    }
    .content-p2 {
        font-size: 5.5vw;
    }
    .content-p3 {
        font-size: 5vw;
    }
    #fname {
        width: 100%;
    }
    #fmail {
        float: left;
        width: 100%;
    }
    .button {
        width: 180px;
        height: 40px;
    }
    .blog-card {
        max-width: 90vw;
    }
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
    .code {
        font-size: 17px;
    }
    .shadow {
        padding-top: 9vh;
        font-size: 40vw;
    }
    .h1we-text {
        height: 12vw;
    }
    .content-h1 {
        font-size: 15vw;
    }
    .content-h2 {
        font-size: 12vw;
    }
    .content-h3 {
        font-size: 10vw;
    }
    .content-h4 {
        font-size: 8.75vw;
    }
    .content-h5 {
        font-size: 8vw;
    }
    .content-h6 {
        font-size: 6.8vw;
    }
    .content-p1 {
        font-size: 4.65vw;
    }
    .content-p2 {
        font-size: 4.3vw;
    }
    .content-p3 {
        font-size: 4vw;
    }
    .blog-card {
        max-width: 90vw;
    }
}

@media only screen and (min-width: 426px) and (max-width: 480px) {
    .code {
        font-size: 19px;
    }
    .shadow {
        padding-top: 9vh;
        font-size: 40vw;
    }
    .h1we-text {
        height: 12vw;
    }
    .content-h1 {
        font-size: 13.5vw;
    }
    .content-h2 {
        font-size: 11vw;
    }
    .content-h3 {
        font-size: 9.5vw;
    }
    .content-h4 {
        font-size: 8vw;
    }
    .content-h5 {
        font-size: 7.25vw;
    }
    .content-h6 {
        font-size: 5.5vw;
    }
    .content-p1 {
        font-size: 4.6vw;
    }
    .content-p2 {
        font-size: 4.25vw;
    }
    .content-p3 {
        font-size: 4vw;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    .code {
        font-size: 21px;
    }
    .shadow {
        padding-top: 9vh;
        font-size: 30vw;
    }
    .h1we-text {
        height: 9.5vw;
    }
    .content-h1 {
        font-size: 12vw;
    }
    .content-h2 {
        font-size: 9.5vw;
    }
    .content-h3 {
        font-size: 7.5vw;
    }
    .content-h4 {
        font-size: 6vw;
    }
    .content-h5 {
        font-size: 40px;
    }
    .content-h6 {
        font-size: 32px;
    }
    .content-p1 {
        font-size: 30px;
    }
    .content-p2 {
        font-size: 24.5px;
    }
    .content-p3 {
        font-size: 20.5px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .code {
        font-size: 23px;
    }
    .shadow {
        padding-top: 9vh;
        font-size: 30vw;
    }
    .h1we-text {
        height: 8vw;
    }
    .content-h1 {
        font-size: 10vw;
    }
    .content-h2 {
        font-size: 8vw;
    }
    .content-h3 {
        font-size: 6.5vw;
    }
    .content-h4 {
        font-size: 6vw;
    }
    .content-h5 {
        font-size: 36.5px;
    }
    .content-h6 {
        font-size: 31px;
    }
}

@media screen and (min-width: 769px) and (max-width:1024px) and (orientation: landscape) {
    .contwidth {
        width: 45% !important;
    }
    .phone-col {
        flex-direction: row !important;
    }
    .blog-content {
        flex-direction: row !important;
    }
    .blog-box1 {
        width: 60%;
    }
    .blog-box2 {
        width: 35%;
    }
}


/*contact media*/

@media only screen and (max-width: 1024px) {
    .contwidth {
        width: 95%;
    }
    .phone-col {
        flex-direction: column;
    }
    .blog-content {
        flex-direction: column;
    }
    .blog-box1 {
        width: 95%;
    }
    .blog-box2 {
        width: 95%;
    }
    /*portfolio media*/
    .port-contain {
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }
    .port-box1 {
        width: 93vw;
        margin: 3vmin auto;
    }
    .port-box2 {
        margin: 3vmin auto;
        width: 93vw;
    }
    .port-photo {
        margin: 4vmin 0px;
        width: 55vw;
        height: 55vw;
    }
    .port-form {
        width: 88vw;
    }
}


/*team media*/

@media only screen and (max-width: 910px) {
    .jourwidth {
        width: auto;
    }
    .team-col {
        margin: 5%;
    }
    .order2 {
        order: 2;
    }
    .order1 {
        order: 1;
    }
    .team-text {
        min-height: 300px;
        height: auto;
    }
}

@media only screen and (min-width: 911px) {
    .team-text .content-p1 {
        font-size: 25px;
    }
    .team-text .content-p2 {
        font-size: 20px;
    }
    .team-text .content-p3 {
        font-size: 16px;
    }
}

@media only screen and (max-width: 350px) {
    /*blog media*/
    .blog-card {
        max-width: 90vw !important;
    }
    /*work media*/
    .frame {
        width: 80vw;
        height: 80vw;
    }
    .frame h1 {
        font-size: 9vw;
    }
    .frame:hover .details {
        width: 66vw;
        height: 66vw;
        padding: 20px;
    }
}

@media only screen and (max-width: 926px) {
    .blog-card {
        max-width: 300px;
    }
}


/*Nav media*/

@media screen and (max-width:1024px) {
    .icobut {
        display: block;
    }
    #myNav {
        width: 100%;
        height: 0%;
        border: 0px solid white;
    }
    .nav-open {
        height: 100% !important;
    }
    #screen {
        width: 100% !important;
    }
    /*Style of html*/
    .nav-logo img {
        width: 30vh;
    }
    /*media load*/
    .loadin {
        padding-left: 0px;
        transition: padding 0.6s cubic-bezier(0.5, 0, 0.5, 1);
    }
}

@media screen and (min-width:1025px) and (max-width:1250px) {
    .social-sym {
        color: #8d8d8d;
        font-size: 22px;
        padding: 3px;
        margin: 0px 2px;
    }
}

@media screen and (orientation: landscape) and (max-width:1024px) {
    .linbutt {
        font-size: 4vh;
    }
}

@media screen and (min-width:1025px) {
    #myNav {
        width: 10% !important;
        height: 100% !important;
    }
    #screen {
        width: 90%;
    }
    /*media load*/
    .loadin {
        padding-left: 10%;
        transition: padding 0.6s cubic-bezier(0.5, 0, 0.5, 1);
    }
}
